import React, { useMemo, useState } from "react";
import { get } from "src/utils";
import { graphql } from "gatsby";
import { getCommonHeroImages } from "src/utils";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import getContentConnectorFields, {
  getContentConnectorAnchors,
} from "src/components/helpers/getContentConnectorFields";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup";
import findSiteType from "src/components/helpers/findSiteType";
import loadable from '@loadable/component'
// import "src/scss/industry/_industryPages.scss";
import { getPageDataJsonPath } from "src/utils";
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
import { SEO, CommonHero } from "src/components/commonComponents";
const DemoFormWithBg = loadable(() => import('src/components/commonComponents/forms/DemoFormWithBg'))
const DemoForm = loadable(() => import('src/components/solutions/DemoForm'))
const RelatedProductCard = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.RelatedProductCard });
const FooterCta = loadable(() => import('src/components/services/footerCta'))
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 const ScrollLinks = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.SecondaryNavbar });
const Resources = loadable(() => import('src/components/solutions/Resources'))


const getImageObject = require("src/../helpers/getImageObject")

const IndustryTemplate = ({ pageContext, path, data }) => {
  const [poupLoad, setPopupLoad] = useState(false)
  const { locale } = pageContext;
  const relatedResourcesData = createRelatedResources(data?.allSitePage?.nodes, {
    hasDescription: true,
  });
  const relatedResourcesEntity = pageContext?.relationships?.fieldRelatedResourceSection
  const newResourceReferenceField = relatedResourcesEntity?.relationships?.fieldResourceReference?.length > 0 && relatedResourcesEntity?.relationships?.fieldRelatedItem?.length === 0 ? true : false
  const relatedResources = createRelatedResources(newResourceReferenceField ? relatedResourcesEntity?.relationships?.fieldResourceReference : relatedResourcesEntity?.relationships?.fieldRelatedItem, {
    isTaxonomyPage: true,
    hasDescription: true,
    locale,
    newResourceReferenceField: newResourceReferenceField
  });
  const fieldMarketoFormTitleId = get(pageContext, "relationships.fieldRefMarketoForm.title");
  const { entityMetatags: metaTags } = pageContext;
  const shouldLoad = useLazyLoadOnScroll();
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentConnector)) {
    contentConnectors = getContentConnectorFields(
      pageContext?.relationships?.fieldContentConnector,
      "industryPage"
    );
  }
  const { id, serial, gaLabel } = extractMarketoValues(
    pageContext.relationships?.fieldRefMarketoForm?.fieldEmbedCode
  );
  const formData = pageContext.relationships?.fieldRefMarketoForm;

  const anchors = getContentConnectorAnchors(contentConnectors)?.map(c => ({ ...c, id: c.id }));
  const heroImage = getImageObject(get(pageContext, "relationships.fieldHeroImage"), get(pageContext, "fieldHeroImage"), "fieldHeroImage");
  const relatedProductsEntity = pageContext.relationships?.fieldRelatedProducts
  let checkForRelatedProductCard = (relatedProductsEntity?.relationships?.fieldRelatedItems && relatedProductsEntity?.relationships?.fieldRelatedItems.length !== 0)
  const checkForRelatedProductReferences = (relatedProductsEntity?.relationships?.fieldProductReference && relatedProductsEntity?.relationships?.fieldProductReference.length !== 0)
  checkForRelatedProductCard = checkForRelatedProductCard || checkForRelatedProductReferences


  const genericCtaEntity = pageContext?.relationships?.fieldGenericCta


  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldVideoSchemaMarkup)
  /**
    * @Open_Graph_Markup
    * Some of the data inside the objects will be done inside SEO component
    */

  const OGMarkup = getOGMarkup(pageContext?.fieldPageTitle, 'industry', { gatsbyImage: heroImage?.shareImage });

  // url - set inside SEO
  // description - set inside SEO
  // Locale - left as default: en_US
  // type - left as default: Webpage

  const siteClass = findSiteType('mc') ? "new-industry-page-mc tw-new-industry-page-mc" : findSiteType('ems') ? "new-industry-page-ems tw-new-industry-page-ems" : ""

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    console.log("count", hreflangAlternatives.length)
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  const pageDataPath = getPageDataJsonPath(path)
  const preloadImages = useMemo(() => getCommonHeroImages(pageContext?.commonHeroProps), [pageContext?.commonHeroProps])

  return (
    <div pagetype="taxonomy" className={"new-product-page tw-new-product-page new-industry-page tw-new-industry-page " + siteClass} tid={pageContext?.entityId}>
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        meta={metaTags}
        title={pageContext.fieldPageTitle}
        schemaMarkup={schemaMarkup}
        schemaMarkupVideo={schemaMarkupVideo}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        preloads={[pageDataPath]}
        preloadedImages={preloadImages}
      />

      {!pageContext.fieldHideHero && (
        <CommonHero
        commonHeroProps={pageContext?.commonHeroProps}
        contentProp={"Industry"}
        onPopupLoad={setPopupLoad}
      />
      )}
      {!!anchors.length &&
        <ScrollLinks
          title={pageContext.fieldTermJumpLinksHeader}
          anchors={anchors}
        />
      }
      <div className="container content-visibility-auto-mobile">
        {contentConnectors.map((connectorEntity, index) => (
          <div key={index} className={`section-container industry ${(!anchors?.length && index == 0) ? (!pageContext?.fieldHideHero) ? 'rm-margin' : 'set-margin' : ''}`} >
            <ContentConnector
              key={index}
              data={connectorEntity}
              locale={locale}
              path={path}
              pageTitleForMarketo={pageContext?.commonHeroProps?.heading}
            />
          </div>
        ))}
        {checkForRelatedProductCard &&
          <div className="section-container industry">
            <section className="section">
              <RelatedProductCard entity={relatedProductsEntity} locale={locale} isRelatedProductReferences={checkForRelatedProductReferences} isWrraper={true} />
            </section>
          </div>
        }
        {!!genericCtaEntity?.status &&
          <div className="section-container industry">
            <section className="section">
              <FooterCta entity={genericCtaEntity} />
            </section>
          </div>
        }
        {pageContext.fieldAddRelated &&
          relatedResourcesData &&
          relatedResourcesData.length !== 0 && (
            <section className="section-container solution-template section solution-related-resources">
              <h3 className="title title-3">
                Resources
              </h3>

              <Resources title="" isCarousel={false} data={relatedResourcesData} />
            </section>
          )}
        {
          relatedResources &&
          relatedResources.length !== 0 && (
            <div className="section-container industry">
              <section className="section">
                <div id="resources" className="jumplinks-anchor tw-block tw-relative -tw-top-[132px] tw-invisible"></div>
                <Resources
                  title={relatedResourcesEntity?.fieldHideTitle ? undefined : relatedResourcesEntity?.title}
                  isCarousel={false}
                  data={relatedResources}
                />
              </section>
            </div>
          )}

          <div className="section-container industry">
            {formData?.fieldVersions !== "advanced" ? <section className={"section taxonomy-form-wrapper full-width-bg-wrapper"}>
              <DemoForm
                fieldFormHeader={formData?.fieldFormHeader}
                description={formData?.fieldFormText?.value}
                image={getImageObject(formData?.relationships?.fieldFormImage, formData?.fieldFormImage)}
                fieldMarketoFormTitleId={fieldMarketoFormTitleId}
                serial={serial}
                formId={id}
                gaLabel={gaLabel}
                shouldLoad={(shouldLoad || poupLoad)}
              />
            </section>
              :
              <section className="section taxonomy-form-wrapper tw-demo-form-bg">
                {<DemoFormWithBg
                  fieldFormHeader={formData?.fieldFormHeader}
                  description={formData?.fieldFormText?.value}
                  image={getImageObject(formData?.relationships?.fieldFormImageWithCover, formData?.fieldFormImage)}
                  bgImage={getImageObject(formData?.relationships?.fieldMediaImage?.relationships?.field_image, formData?.relationships?.fieldMediaImage?.fieldImage)}
                  fieldMarketoFormTitleId={fieldMarketoFormTitleId}
                  serial={serial}
                  formId={id}
                  gaLabel={gaLabel}
                  pageTitle={pageContext?.commonHeroProps?.heading}
                  cta={formData?.fieldCtaUrl}
                  shouldLoad={(shouldLoad)}
                />}
              </section>
              }
          </div>
      </div>
    </div>
  );
};

export default IndustryTemplate;

export const query = graphql`
  query resourcesByIndustryId($pageId: String, $locale: String) {
    allSitePage(
      limit: 3
      filter: {componentChunkName: {eq: "component---src-templates-common-resources-template-js"}, context: {locale: {eq: $locale}, industryIds: {elemMatch: {industryId: {eq: $pageId}}}}}
    ) {
      nodes {
        ...resourceCards
      }
    }
  }
`;